/* max-w-[1200px] h-[200px] w-full m-auto py-16 px-4 relative */
:root
{
    --backgroundColor: #142738;
    --metricsCard: white;
    --textColor: white;
    --secondaryTextColor: #142738; 
}

main
{
    background-color: var(--backgroundColor);
}

/* container parent for the carousel */
.carouselParent
{
    margin-bottom: 3%;
    padding-top: 0.4%;
}

/* images in the carousel */
.imgSlider
{
    height: 70vh ;
    object-fit: cover;
    width: 100%;
    object-position: 0 8%;
}

/* setting background to caption in carousel to make caption visible */
.imgCaption
{
    background-color: var(--backgroundColor);
    opacity: 0.9;
    color: var(--metricsCard);
    border-radius: 10px;
}



/* Fitness metric row and text */
.fitnessMetricsHeader
{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--textColor);
    margin-bottom: 2%;
}

/* row of each card metric */
.metricsRow
{
    margin-bottom: 3%;
}

/* heading  of each metric card */
.metricsCard
{
    
    background-color: #142738 !important;
    color: var(--backgroundColor);
    text-align: center;
    border: 2px solid var(--backgroundColor);
    border-radius: 5px;
    text-align: center;
    width: 20rem;
    height: 40vh;
    box-shadow: none !important;
    
}


/* description of each metric card */
.metricsCardDescription
{
    background-color: var(--backgroundColor);
    color: var(--textColor);
    border-left: 2px solid var(--textColor);
    margin-left: -5%;
}




