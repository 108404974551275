.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#workOutBtns > *{
  margin-right: 2rem;
  margin-top: 1rem;
  min-width: 153px;
}

#fitnessBtns{
  display: flex !important; 
  justify-content: space-evenly !important;
  margin-right: 2rem;
  margin-top: 1rem;
}

.fitnessBtnWidth {
  min-width: 12% !important;
}
.exerciseTitle {
  margin-top: 1rem;
}

.youtubeVideos{
  margin-bottom: 2rem;
}

/* * {
  background: #000 !important;
  color: #0f0 !important;
  outline: solid #f00 1px !important;
} */

header {
  position: fixed;
  top: 0%;
  left: 0%;
  height: 60px;
  width: 100%;
  margin: 0% auto;
}

.h5Text{
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 300;
    line-height: 1.1;
    margin-left: 1.2% !important;

}

.card-body2.overlay {
  background-color: #142738;
  border: solid 1px #93DEFF;
  color: white !important;
  border-radius: 1rem;
  
}

.card-body2 {
  background-color: #142738;
  /* border-right: solid 1px #93DEFF; */
  color: white !important;
  
}

.containedBtn{
  background: #93deffed !important;
  color: black !important;
}

.outlinedBtn{
  background: #00cfff4d !important;
  color: rgb(255, 255, 255) !important;
}

.clearBtn{
  background: #00cfff4d !important;
  color: black !important;
  align-self: center;
}